import configs from '../../configs'
import mutations from './mutations'
import auth from '@/auth'

const { product, theme } = configs
const { isRTL, globalTheme } = theme

// state initial values
const state = {
  product,

  // app theme dark or light
  globalTheme,

  // themes and layout configurations
  isRTL,

  // custom vars
  selectedLang: 'en',
  isLoggedIn: false,
  rowsPerPageItemsGlobal: [25, 100, 250, 500],
  userRoles: auth.fetchRoles(),
  defaultFrontend: {
    baseUrlRest: '',
    defaultLocale: '',
    logServerUrl: '',
    metricServerUrl: '',
    setPasswordUrl: '',
    visualisationServerUrl: '',
    environmentName: ''
  },
  savedLocations: [],
  userSettings: {
    general: {
      selectedLanguage: 'en'
    },
    visuals: {
      selectedTheme: 'light',
      selectedColorScheme: 'default'
    },
    display: {
      showCreatedOn: true,
      showModifiedOn: true,
      showId: true,
      showComments: false,
      showCopyPaste: false,
      showFinishedOn: false,
      showStepType: false
    }
  },
  snackbarQueue: [],
  processUndoStack: [],
  processRedoStack: [],
  processCurrent: {},
  formValid: false
}

const getters = {
  formValid(state) {
    return state.formValid
  },
  selectedLang(state) {
    return state.selectedLang
  },
  isLoggedIn(state) {
    return state.isLoggedIn
  },
  rowsPerPageItemsGlobal(state) {
    return state.rowsPerPageItemsGlobal
  },
  userRoles(state) {
    return state.userRoles
  },
  defaultFrontend(state) {
    return state.defaultFrontend
  },
  globalTheme(state) {
    return state.globalTheme
  },
  savedLocations(state) {
    return state.savedLocations
  },
  userSettings(state) {
    return state.userSettings
  },
  currentSnackbar(state) {
    return state.snackbarQueue.length ? state.snackbarQueue[state.snackbarQueue.length - 1] : []
  }
}

const actions = {
  formValid({ commit }, data) {
    commit('formValid', data)
  },
  selectedLang({ commit }, data) {
    commit('selectedLang', data)
  },
  isLoggedIn({ commit }, data) {
    commit('isLoggedIn', data)
  },
  defaultFrontend({ commit }, data) {
    commit('defaultFrontend', data)
  },
  globalTheme({ commit }, data) {
    commit('globalTheme', data)
  },
  savedLocationsSave({ commit }, data) {
    commit('savedLocations', data)
  },
  userSettingsSave({ commit }, data) {
    commit('userSettings', data)
  },
  addSnackbar({ commit }, payload) {
    commit('pushSnackbar', payload)
  },
  removeSnackbar({ commit }) {
    commit('shiftSnackbar')
  },
  saveProcessState({ commit }, process) {
    commit('processPushUndo', process)
    commit('processClearRedo')
  },
  undoProcess({ commit }) {
    commit('processUndo')
  },
  redoProcess({ commit }) {
    commit('processRedo')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
